<template>
    <auth-page-template heading="Create an account">
        <template #buttons>
            <div v-if="currentSection === 'enter-otp'" @click="currentSection='enter-mobile'" class="text-decoration-none cursor-pointer">
                <i class="fa fa-chevron-left mr-2"></i> Back
            </div>
        </template>
        <template #subSection>
            <div class="text-center">
                <p class="mb-41">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolore magna aliqua.
                </p>
                <p class="mb-3 font-poppins-medium fs-lg-3">Mobile Number Verification</p>
            </div>
        </template>
        <transition name="fade" mode="out-in">
            <div v-if="currentSection === 'enter-mobile'" key="enterMobile">
                <div>
                    <validated-input label="Enter mobile number"></validated-input>
                </div>
                <div class="text-center">
                    <btn @click="currentSection='enter-otp'" design="" block class="btn-bg-white mt-4" size="lg"
                         border-radius="4" text="Get OTP"></btn>
                    <p class="mt-2">Already an account ?
                        <router-link to="/login/">Log in</router-link>
                    </p>
                </div>
            </div>
            <div v-else key="enterOtp">
                <div>
                    <validated-input type="password" label="Enter the OTP"></validated-input>
                </div>
                <div class="text-center">
                    <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4"
                         text="Verify Number"></btn>
                    <p class="mt-2">Not yet received ?
                        <span>Resend</span>
                    </p>
                </div>
            </div>
        </transition>

    </auth-page-template>
</template>

<script>
export default {
    name: 'CreateAnAccount',
    data () {
        return {
            currentSection: 'enter-mobile'
        };
    }
};
</script>

<style scoped>

</style>
